import React, { useEffect} from 'react'

import { BanditBot } from '../components/BanditBot/BanditBot';
// import App from './App';
import * as bootstrap from 'bootstrap/dist/js/bootstrap'

export const ChatBotOffCanvas = () => {

  const openOffCanvas = () => {
   
    setTimeout(() => {
      const offCanvasElement = document.getElementById('offcanvasExample');
      const offCanvas = new bootstrap.Offcanvas(offCanvasElement);
      offCanvas.show();
    }, 500)
  };

  window.addEventListener('message', function(event) {
    
        // Process the received message
        console.log('Received message:', event.data);

        if(event.data == "openBot") {
          openOffCanvas()
        }
   
});

const handleClose = ()=>{
  if (window.parent) {
    // Post message to parent window
    setTimeout (() =>{
      window.parent.postMessage('closeBot', '*');
      const offcanvasElement = document.getElementById('offcanvasExample');
      const offcanvas = new bootstrap.Offcanvas(offcanvasElement);
      offcanvas.hide();
    }, 500)
  }
}


  return (
    <div className="App">
        <BanditBot 
        handleClose={handleClose}
        />
      <header className="App-header">
      </header>
    </div>
  )
}
