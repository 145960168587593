import React from 'react'
import { BanditBotOffCanvasTBS } from './BanditBotOffCanvas'
export const BanditBot = ({ handleClose}) => {
  return (
    <>
      <BanditBotOffCanvasTBS
       handleClose={handleClose}
      />
    </>
  )
}
